/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

* {
  padding: 0;
  margin: 0;
}


body {
  background-image: url("./home-1.png"), linear-gradient(180deg, rgba(33,177,225,1) 0%, rgba(32,52,154,1) 23%, rgba(4,11,49,1) 90%);
  overflow-x: hidden;
}

h1 { white-space: pre-line; }
h2 { white-space: pre-line; }
p { white-space: pre-line; font-family: monospace; }
button { white-space: pre-line; font-family: monospace; }

/* BOOTSTRAP OVERRIDE */
nav {
  transition-duration: 400ms;
  width: 100vw;
}

.pointer {
  cursor: pointer;
}

/* SCALING */
.full-page {
  width: 100vw;
  height: 100vh;
}

.fl-1 {
  flex: 1
}

/* POSITIONING */
.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-desktop { display: none!important; }

nav > img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* GRIDS */
.grid {
  display: grid;
  gap: 1rem;
}

.grid-2 {grid-template-columns: repeat(2,1fr);}
.grid-3 {grid-template-columns: repeat(3,1fr);}
.grid-4 {grid-template-columns: repeat(4,1fr);}
.grid-5 {grid-template-columns: repeat(5,1fr);}
.grid-6 {grid-template-columns: repeat(6,1fr);}

/* Widgets */

.async-gallery > button {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  background-size: cover;
  background-position: top;
  transition-duration: 400ms;
  cursor: pointer;
  border: none;
  color: transparent;
}

.async-gallery > button:hover {
  transform: scale(1.05);
}

.async-gallery > button:hover > h4 {
  color: white;
  transition-duration: 400ms;
  z-index: 1;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  margin: 0;
  padding: 0;
}

.async-gallery > button:hover > div {
  background-color: #00000077;
  transition-duration: 200ms;
}

.async-gallery > button > div {
  width: 100%;
  height: 100%;
  background: transparent;
}

.nft-amount {
  text-align: center;
  user-select: none;
  font-size: 2rem;
}

.overlay {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}

.hero-action-right-gift {
  position: absolute;
  top: 50%;
  right:-3rem;
  transform: translateY(-50%);
}

.specs-white-whale {
  border-radius: 50%;
}

.member-benefits-container {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  backdrop-filter: blur(6px);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
}

.member-benefits-container > img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  flex:  1;
  margin-bottom: 1rem;
}

.member-benefits-container > p {
  text-align: center;
}

.menu {
  position: fixed;
  display: flex;
  top: 0; 
  left: 0;
  width: 25%;
  height: 100%;
  background: #EFEAE3;
  box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: 0.5s ease-in-out;
  justify-content: center;
}

.menu-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 6%);
  padding: 0rem 2rem;
}
 
.menu-text {
  text-decoration: none;
  color: #171717;
}

.menu-text:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.menu-text:active {
  color: white;
  text-decoration: none;
}

.footer {
  min-height: 50rem;
}

.footer-text {
  font-family: monospace;
  text-decoration: none;
  color: white;
}

.footer-text:hover {
  font-weight: bold;
  color: #cccccc;
  cursor: pointer;
  text-decoration: none;
}

.roadmap-box {
  width: 400px;
  height: 200px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
  
}

@media screen and (max-width: 768px) {
  .no-mobile { display: none!important; }
  .no-desktop { display: flex!important; }
  .footer {background-size: contain!important; min-height: 40rem;}
  .footer-text {margin-bottom: 1rem;}
  .grid-2 {grid-template-columns: repeat(1,1fr);}
  .grid-3 {grid-template-columns: repeat(1,1fr);}
  .grid-4 {grid-template-columns: repeat(2,1fr);}
  .grid-5 {grid-template-columns: repeat(1,1fr);}
  .grid-6 {grid-template-columns: repeat(1,1fr);}

  .menu {
    position: fixed;
    display: flex;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background: #EFEAE3;
    box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: 0.5s ease-in-out;
    justify-content: center;
    text-align: center;
  }
}
