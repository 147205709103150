.hero-emphasis {
  background: linear-gradient(93.29deg, #EB8200 3.47%, #FEB827 127.71%);
  border: 2px solid #EB8200;
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
  width: 290px;
  height: 70px;
  color: white;
}

#about { width: 100vw!important;}
#specs { width: 100vw!important; }
#mentorship { width: 100vw!important; }
#benefits { width: 100vw!important; }

.hero-emphasis:hover {
  background: linear-gradient(272.7deg, #EB8200 6.57%, #FEB827 112.69%);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.15);
  border-radius: 40px;
  width: 290px;
  height: 70px;
  color: white;
}

.hero-normal {
  background: linear-gradient(93.29deg, #FFFFFF 3.47%, #EFEAE3 127.71%);
  border: 2px solid white;
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
  width: 290px;
  height: 70px;
}

.hero-normal:hover {
  background: linear-gradient(92.16deg, #FFFFFF 2.99%, #EFEAE3 74.28%);
  border: 2px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
  width: 290px;
  height: 70px;
}

.hero-main {
  background-image: url(./assets/1.png), linear-gradient(180deg, #EFEAE4 0%, #EFEAE4 60%, rgb(5 179 232) 100%);
  background-color: #EFEAE4;
  background-position-y: calc(100% + 9rem);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}
.hero-main::after {
  content: "";
  height: 5rem;
  width: 100%;
  bottom: 0;
  position: absolute;
  transform: translateY(50%);
}
.nav-button {
  background: linear-gradient(93.29deg, #EB8200 3.47%, #FEB827 127.71%);
  border: 2px solid #EB8200;
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
  padding: 0 1rem;
  height: 50px;
  color: white;
}

.nav-button-disabled {
  background: linear-gradient(93.29deg, #EBEBE4 3.47%, #b9b9b2 127.71%);
  border: 2px solid #898983;
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
  padding: 0 1rem;
  height: 50px;
  color: white;
}

.nav-button:hover {
  /* To be changed */
  background: linear-gradient(93.29deg, #EB8200 11.47%, #FEB827 40.71%); 
}

.roadmap {
  display: flex;
  flex-direction: row;
}

.roadmap > div {
  display: flex;
  flex: 1;
  width: 85vw;
  height: 100%;
}

.roadmap > div > p {
  background-size:cover;
  padding: 4rem;
  color: white;
  text-align: center;
}

.roadmap > section {
  position: relative;
  width: 0.175rem;
  background-color: #FFFFFF55;
 }

 .whales-border {
  border-radius: 50px;
  border: 1px solid rgba(255,255,255,0.2);
  padding: 5px;
 }

 .whales-button {
  background: linear-gradient(93.29deg, #EB8200 3.47%, #FEB827 127.71%);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
  padding: 25px;
  width: 200px;
 }

 .whales-button:hover {
  background: linear-gradient(272.7deg, #EB8200 6.57%, #FEB827 112.69%);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.15);
  border-radius: 40px;
  padding: 25px;
  width: 200px;
  font-weight: bold;
 }

 .mint-container {
  background: #4914C2;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  width: 33%;
  padding: 0 0 50px 0;
 }

 .mint-container-top {
  background: linear-gradient(93.29deg, #EB8200 3.47%, #FEB827 127.71%);
  box-shadow: 0px 8px 32px rgba(244, 155, 18, 0.1);
  border-radius: 32px 32px 0 0;
  height: 10rem;
 }

 .mint-button {
  background: linear-gradient(93.29deg, #EB8200 3.47%, #FEB827 127.71%);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
  padding: 25px;
  width: 80%;
 }

 .mint-button:hover {
  background: linear-gradient(272.7deg, #EB8200 6.57%, #FEB827 112.69%);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 32px 32px rgba(244, 155, 18, 0.15);
  border-radius: 40px;
  padding: 25px;
  width: 80%;
  font-weight: bold;
 }

 .pagination-button {
   display: flex;
   background: #00000000;
   border: none;
   color: white;
   padding: 0 1rem;
 }

 .pagination > ul {
  flex-direction: row;
  display: flex;
  color: white;
}


.pagination > ul > li {
  border: 2px solid rgba(255, 255, 255, 0.2)!important;
  box-sizing: border-box;
  filter: drop-shadow(0px 8px 32px rgba(244, 155, 18, 0.1));
  background-color: #00000000;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  color: white;
}

.pagination > ul :first-child {border: none!important;}
.pagination > ul :last-child {border: none!important;}

.pagination > ul > li {
  list-style: none;
  margin: 0 1rem;
  position: relative;
}

.pagination > ul > li > a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagination > ul > .selected {
  background: linear-gradient(93.29deg, #EB8200 3.47%, #FEB827 127.71%)!important;
  border: 2px solid rgba(255, 255, 255, 0.2)!important;
  box-sizing: border-box;
  box-shadow: 0px 8px 32px rgba(244, 155, 18, 0.1);
  border-radius: 40px;
}

.about-bubble {
  max-Width: 80%;
  height: 100%;
}

.header {
  font-size: 72px;
  font-weight: 300;
}

.about-title {
  font-size: 36px;
  font-weight: 400;
}

.specs-desc {
  font-size: 36px;
  font-weight: 300;
}

.specs-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.specs-fish {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.mentorship-text {
  border: 75px solid;
  border-image: url(./assets/mentorship-bg-1.svg) 75 95 95 75 fill;
  color: white;
  background-position-y: '0.3rem',
}

.please-connect {
  border: 30px solid;
  border-image: url(./assets/mentorship-bg-1.svg) 40 75 100 50  fill;
  color: white;
}

.mentorship-text-2 {
  border: 75px solid;
  border-image: url(./assets/mentorship-bg-2.svg) 50 120 100 90 fill;
  color: white;
  background-position-y: '0.3rem',
}

.roadmap-text-1 {
  border: 75px solid;
  border-image: url(./assets/roadmap-box-active.png) 120 120 150 120 fill;
  color: white;
  background-position-y: '0.3rem',
}

.roadmap-text-2 {
  border: 75px solid;
  border-image: url(./assets/roadmap-box-inactive.png) 130 170 160 80 fill;
  color: white;
  background-position-y: '0.3rem',
}

.roadmap-text-3 {
  border: 75px solid;
  border-image: url(./assets/roadmap-box-inactive.png) 120 220 220 80 fill;
  color: white;
  background-position-y: '0.3rem',
}

.roadmap-text-4 {
  border: 75px solid;
  border-image: url(./assets/roadmap-box-inactive.png) 100 300 300 110 fill;
  color: white;
  background-position-y: '0.3rem',
}

.logo {
  width: 100%;
  max-width: 10rem;
  height: auto;
  transition: all 0.4s ease-in-out;
}

.logo-visible {
  opacity: 1;
}

.logo-invisible {
  opacity: 0;
}

.language-button {
  border: none;
  background: none;
  width: 50px;
  height: 50px;
}

.language-image {
  width: 100%;
  height: 100%;
}

.language-button:hover {
  border: none;
  background: none;
  width: 50px;
  height: 50px;
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.language-button:active {
  border: none;
  background: none;
  width: 50px;
  height: 50px;
  transform: scale(0.9);
  transition: all 0.01s ease-in-out;
}

.hero-text {
  font-size: 48px;
  margin-top: 10%;
}

.hero-desc {
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: 1rem;
  letter-spacing: 1px;
  text-align: center;
}

.roadmap-fish-1 {
  width: 100%;
  max-width: 300px;
  height: 80%;
  max-height: 250px;
}

.roadmap-fish-2 {
  display: none;
}

.roadmap-padding-1 {
  padding-left: 20%;
}

.roadmap-padding-2 {
  padding-right: 20%;
}

.top-wave {
  width: 100vw; 
  position: absolute; 
  bottom: -1.5rem;
}

.member-benefits {
  padding-bottom: 8rem;
}

.container-padding {
  padding-top: 200px;
}

.menu-link {
  text-decoration: none;
}

@media screen and (max-width: 1366px) {
  .hero-text {
    margin-top: 8rem;
    font-size: 36px;
  }

  .logo {
    width: 100%;
    max-width: 7rem;
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .hero-text {
    margin-top: 8rem;
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .top-wave {
    width: 200vw; 
    position: absolute; 
    bottom: -1rem;
  }
  .hero-main::after {
    height: 3rem;
  }
  .mint-container {
    width: calc(100% - 2rem);
   }

  .hero-text {
    margin-top: 8rem;
    font-size: 28px;
    margin-bottom: 10px;
  }

  .hero-desc {
    text-align: center;
    margin-bottom: 30px;
  }

  .header {
    font-size: 48px;
    font-weight: 300;
    margin-top: 5%;
  }

  .about-title {
    font-size: 24px;
    font-weight: 400;
  }

  .specs-desc {
    font-size: 24px;
    font-weight: 300;
  }

  .mentorship-text {
    border: 75px solid;
    border-image: url(./assets/mentorship-bg-2.svg) 60 120 100 60 fill;
    color: white;
    background-position-y: '0.3rem',
  }

  .logo {
    width: 100%;
    max-width: 6rem;
    height: auto;
  }

  .logo-invisible {
    opacity: 0;
  }

  .hero-main {
    background-image: url(./assets/2.png), linear-gradient(180deg, #EFEAE4 0%, #EFEAE4 60%, rgb(5 179 232) 100%);
    background-color: #EFEAE4;
    background-position-y: calc(100% + 9rem);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
  }

  .member-benefits {
    padding-bottom: 5rem;
  }

  .container-padding {
    padding-top: 130px;
  }

  .roadmap-fish-1 {
    width: 100%;
    max-width: 200px;
    height: 80%;
    max-height: 150px;
  }

  .hero-mobile-space {
    height: 1rem;
    width: 100%;
    background: #fff;
    z-index: 1;
  }
}

@media screen and (max-width: 480px) {
  .header {
    font-size: 36px;
    font-weight: 300;
    margin-top: 5%;
  }

  .specs-desc {
    font-size: 16px;
    font-weight: 300;
  }
}
